import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { useUpdateOTLds } from '@phx-husky/use-update-ot-lds';

import { Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';

import { LdsPageProps } from './models';

const LdsPage: FC<{ data: LdsPageProps }> = ({ data: { lds, ldsSeoPage } }) => {
  const { body, businessId, versionId, platform } = lds;
  const [bodyParsed, titleParsed, contentParsed] =
    body.match(/<p><b>(.*?)<\/b><\/p>(<p.*?)$/) || [];

  const { updatedLdsContent, isCheckingLdsVersion } = useUpdateOTLds({
    baseUrl: process.env.GATSBY_LDS_BASE_URL as string,
    businessId,
    versionId,
    platform,
  });

  return (
    <Layout>
      {ldsSeoPage?.seoMetaTitle ? (
        <>
          <Seo
            {...{
              title: ldsSeoPage.seoMetaTitle,
              description: ldsSeoPage.seoMetaDescription,
              keywords: ldsSeoPage.seoMetaKeywords,
            }}
          />
          <PageSchema
            type="WebPage"
            name={ldsSeoPage.seoMetaTitle}
            data={{
              metaTitle: ldsSeoPage.seoMetaTitle,
              metaDescription: ldsSeoPage.seoMetaDescription,
              metaKeywords: ldsSeoPage.seoMetaKeywords,
            }}
          />
        </>
      ) : null}
      {!isCheckingLdsVersion && (
        <div className="content container politics">
          <div className="content__title">
            <h1 className="title" dangerouslySetInnerHTML={{ __html: titleParsed }} />
          </div>
          {updatedLdsContent ? (
            <div
              className="content__body"
              dangerouslySetInnerHTML={{ __html: updatedLdsContent }}
            />
          ) : (
            <>
              {bodyParsed ? (
                <div
                  className="content__body"
                  dangerouslySetInnerHTML={{ __html: contentParsed }}
                />
              ) : null}
              {body ? (
                <div className="content__body" dangerouslySetInnerHTML={{ __html: body }} />
              ) : null}
            </>
          )}
        </div>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($seoContentType: String) {
    ldsSeoPage(ldsContentType: { eq: $seoContentType }) {
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
    }
    lds(seoContentType: { eq: $seoContentType }) {
      body
      contentType
      fields {
        slug
      }
      businessId
      versionId
      platform
    }
  }
`;

export default LdsPage;
